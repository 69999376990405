<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :edit-mode="false"
  >
    <template v-if="entityLoaded">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col cols="12" md="12">
          <user-view-user-info-card
            :entity="entity"
            :endpoint="config.endpoint"
            :edit-path="`${config.route}-edit`"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="12">
          <b-tabs v-if="rightsLoaded" pills vertical>
            <b-tab
              v-for="(rightGroup, index) in entity.rights"
              :key="rightGroup.title"
              :title="$t(rightGroup.title)"
              :active="index == 0"
            >
              <user-permissions-card :rights-group="rightGroup" view-mode />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </template>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BRow, BCol } from 'bootstrap-vue'
import { formUtils } from '@core/mixins/ui/forms'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserPermissionsCard from '../UserPermissionsCard.vue'

import storeModule from '../userStoreModule'

export default {
  components: {
    BRow,
    BCol,
    EntitySingle,
    BTab,
    BTabs,

    // Local Components
    UserViewUserInfoCard,
    UserPermissionsCard,
  },
  mixins: [formUtils],
  data() {
    return {
      entity: {},
      entityLoaded: false,
      rightsLoaded: false,
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'user',
        endpoint: 'user',
        route: 'users',
        title: {
          single: this.$t('User'),
          plural: this.$t('Users'),
        },
      }
    },
  },
  mounted() {
    this.$watch('entityLoaded', this.fetchUserGroupRights)
  },
  methods: {
    fetchUserGroupRights() {
      if (this.entityLoaded) {
        this.$store
          .dispatch('app-common/fetchUserGroupRights', {
            id: this.entity.id,
          })
          .then(response => {
            this.entity.rights = this.formatRightsForTable(response.data)
            this.rightsLoaded = true
          })
      }
    },
  },
}
</script>
